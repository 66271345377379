import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import { Component } from "vue-property-decorator";
import Container from "typedi";
import Notification from '@/modules/common/services/notification.service'

@Component({
    name: 'GtrStripeReturn',
})
export default class GtrStripeReturnView extends GtrSuper {
    data() {
        return {
            error: null as null | string
        }
    }

    private sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async mounted() {
        const urlParams = new URLSearchParams(window.location.search)
        const stripe_session_id = urlParams.get('stripe_session_id')
        const widget = urlParams.get('widget')
        if (stripe_session_id) {
            const stripeData = {
                event_identifier: this.$route.params.event_identifier,
                pagenum: this.$route.params.pagenum,
                widget: widget,
                session_id: stripe_session_id
            }
            const formDataRequest = {
                event_identifier: this.$route.params.event_identifier,
                page_number: this.$route.params.pagenum,
                language: 'en'
            }
            

            await this.sleep(500)
            const response = await this.$store.dispatch('payment/getStripePayments', stripeData)
            let exists = response.data.exists
            let success = response.data.success
            let session_status = response.data.session_status
            while (session_status === 'complete' && !exists) {
                await this.sleep(1000)
                const response = await this.$store.dispatch('payment/getStripePayments', stripeData)
                exists = response.data.exists
                success = response.data.success
                session_status = response.data.session_status
            }

            let nextPage = 0
            let viewable_reg_pages = []
            try {
                const formData = await this.$store.dispatch('formbuilder/getForm', formDataRequest)
                viewable_reg_pages = formData.data.viewable_reg_pages
            }
            catch(error) {
                viewable_reg_pages = error.response.data.viewable_reg_pages
            }
            nextPage = viewable_reg_pages[viewable_reg_pages.length - 1]

            if (success) {
                this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$route.params.event_identifier + '/register/' + nextPage)
            } else {
                Container.get(Notification).error('Transaction failed')
                this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$route.params.event_identifier + '/register/' + this.$route.params.pagenum)
            }


        }
    }

}
